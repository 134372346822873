<template>
  <div class="layout-subcontainer">
    <h1 class="tit-h1">콜백관리</h1>
    <div class="box-wrap">
      <div class="box-ct d-flex">
        <div class="">
          <div class="form-inp sm">
            <v-select
                :items="this.$store.getters['userStore/GE_USER_COMPANY']"
                item-text="CD_NM"
                item-value="CD"
                outlined
                hide-details
                label="회사구분"
                placeholder="선택하세요"
                v-model="ASP_NEWCUST_KEY"
                @change="getUserGroup()"
                :readonly="RDOnly"
            >
              <template v-slot:label>
                회사구분
                <v-icon color="#EF9191">mdi-circle-small</v-icon>
              </template>
            </v-select>
          </div>
          <v-menu
              v-model="menu1"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
              content-class="calendar-modal"
              color="#FFF"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                  v-model="dateRangeText"
                  v-bind="attrs"
                  v-on="on"
                  readonly
                  outlined
                  hide-details
                  class="form-inp icon-calendar ml-2"
                  append-icon="svg-calendar"
                  label="접수일자"
              ></v-text-field>
            </template>
            <div class="datepicker-range">
              <v-date-picker
                  v-model="dates[0]"
                  no-title
                  dark
                  color="#F7AFAE"
                  @change="startDate"
                  :max="dates[1]"
                  :first-day-of-week="1"
                  locale="ko-KR"
                  :day-format="mixin_getDate"
              ></v-date-picker>
              <v-date-picker
                  v-model="dates[1]"
                  dark
                  color="#F7AFAE"
                  no-title
                  show-current="false"
                  :min="dates[0]"
                  @change="endDate"
                  :first-day-of-week="1"
                  locale="ko-KR"
                  :day-format="mixin_getDate"
              ></v-date-picker>
            </div>
            <div class="text-center pt-3 pb-3">
              <v-btn
                  outlined
                  class="btn-default"
                  @click="menu1 = false"
              >확인</v-btn>
            </div>
          </v-menu>
          <div class="form-inp ml-2">
            <v-select
                single-line
                v-model="selected_userGroup"
                :items="userGroup"
                item-value="CD"
                item-text="CD_NM"
                outlined
                label="팀"
                multiple
                hide-details
              >
                <template v-slot:selection="{ item, index }">
                  <v-chip v-if="index < 1" small>
                    <span>{{ item.CD_NM }}</span>
                    <template>
                      <v-btn
                        icon
                        x-small
                        class="svg-close20 ml-1"
                        v-show="dropItemRemove === true"
                        @click.stop="removeDropGroup(index)"
                      ></v-btn>
                    </template>
                  </v-chip>
                  <span v-if="index === 0" class="grey--text text-caption">
                    ...
                  </span>
                </template>
              </v-select>
          </div>
          <div class="form-inp ml-2">
            <v-select
                :items="eotTyCodeList"
                item-text="CD_NM"
                item-value="CD"
                outlined
                hide-details
                label="처리구분"
                placeholder="선택하세요"
                v-model="EOT_TY"
            ></v-select>
          </div>
          <v-text-field
              class="form-inp sm ml-2"
              name="name" label="사용자 ID" outlined hide-details
              v-model="USER_ID"
          >
          </v-text-field>
          <v-text-field
              class="form-inp sm ml-2"
              name="name" label="사용자명" outlined hide-details
              v-model="USER_NM"
          >
          </v-text-field>
          <v-text-field
              class="form-inp sm ml-2"
              name="name" label="닉네임" outlined hide-details
              v-model="USER_NICK"
          >
          </v-text-field>
          <!-- 조회 버튼 -->
          <v-btn dark small
            v-if="this.mixin_set_btn(this.$options.name, 'btnPopupUser')"
            class="btn-main-search pa-0 ml-2" min-width="32px" height="32px" plain
            @click="[btnUser(), showDialog('MP02')]">
            <v-icon small class="svg-main-searh white"></v-icon>
          </v-btn>
          <!-- 새로고침 버튼 -->
          <v-btn dark small
            v-if="this.mixin_set_btn(this.$options.name, 'btnInitUser')"
            class="btn-main-refresh pa-0 ml-2" min-width="32px" height="32px"
            @click="resetBtn"
            plain
            >
            <v-icon small class="svg-main-refresh"></v-icon>
          </v-btn>
          <!-- 모달 : 고객정보  -->
          <v-dialog max-width="1400" max-height="1200" persistent v-model="dialogMP02" v-if="dialogMP02" content-class="square-modal">
            <dialog-MP02
            headerTitle="고객정보"
            :userInfo="userInfo"
            @selectUser="[hideDialog('MP02'), setUser()]"
            @hide="hideDialog('MP02')"
            >
            </dialog-MP02>
          </v-dialog>
        </div>
        <div class="ml-auto align-self-center">
          <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnSearch')" outlined class="btn-etc2 ml-3" @click="getListOrNot" @keyup.enter="getListOrNot">조회</v-btn>
        </div>
      </div>
    </div>
    <!-- 콜백접수리스트 -->
    <div class="box-wrap">
      <h2 class="tit-h2 d-flex">콜백접수리스트
        <div class="ml-auto">
          <v-btn 
            v-if="this.mixin_set_btn(this.$options.name, 'btnPopupDist')"
            outlined class="btn-default" @click="showDialog('M110303')">사용자배분</v-btn>
            <v-dialog max-width="1400" persistent v-model="dialogM110303" v-if="dialogM110303"
              content-class="square-modal modal-white">
              <dialog-m110303
                  headerTitle="상담원배분팝업"
                  :param="params"
                  @hide="hideDialog('M110303')"
                  @select="getListData"
                  p02
              >
              </dialog-m110303>
            </v-dialog>
          <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnComplete')" outlined class="btn-default ml-2" @click="processOrNot">완료처리</v-btn>
          &nbsp; |&nbsp;
          <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnExcelDown')" outlined class="btn-point ml-2" @click="downloadExcel('mainTable', '콜백관리')">엑셀다운로드</v-btn>
        </div>
      </h2>
      <div class="box-ct">
        <v-data-table
            dense
            fixed-header
            height="512px"
            :headers="gridDataHeaders"
            :items="gridDataText"
            :items-per-page="30"
            item-key="index"
            :page.sync="page"
            hide-default-footer
            class="grid-default"
            show-select
            @page-count="pageCount = $event"
            v-model="checkedList"
            id="mainTable"

            :item-class="isActiveRow"
            @click:row="rowSelect"
        >
        <template v-slot:item.IN_DATE ="{ item }">
          <div> {{dateFormat(item.IN_DATE) }}</div>
        </template>
        <template v-slot:item.IN_TIME="{ item }">
          <div> {{timeFormat(item.IN_TIME)}} </div>
        </template>
        <template v-slot:item.SEND_MAN_NO="{ item }">
          <div> {{phoneFormat(item.SEND_MAN_NO)}} </div>
        </template>
          <template v-slot:item.CLBK_TEL_NO="{ item }">
            <div> {{phoneFormat(item.CLBK_TEL_NO)}} </div>
          </template>
          <template v-slot:item.CUST_NO="{ item }">
            <div> {{juminFormat(item.CUST_NO)}} </div>
          </template>
          <template v-slot:item.DSTR_DATE="{ item }">
            <div> {{dateFormat(item.DSTR_DATE)}} </div>
          </template>

        </v-data-table>
        <div class="grid-paging text-center pt-2">
          <v-pagination
              v-model="page"
              :length="pageCount"
              :total-visible="totalVisible"
              next-icon="svg-paging-next"
              prev-icon="svg-paging-prev"></v-pagination>
        </div>
        <p class="caption text-left">
          총<strong class="clr-error">{{ gridDataText.length }}</strong
        >건
        </p>
      </div>
    </div>
    <!--// 콜백접수리스트 -->
  </div>
</template>

<script>
import api from '../../store/apiUtil.js';
import DialogMP02 from "./MP02.vue";
import DialogM110303 from "./M110303.vue";
import {mixin} from "../../mixin/mixin.js";


export default {
  mixins:[mixin], // mixin 등록
  name: "MENU_M110302", //name은 'MENU_' + 파일명 조합
  components: {
    DialogMP02,
    DialogM110303,
  },
  data() {
    return{
      menu1: false,
      dialogMP02: false,
      dialogM110303: false,

      params:
        {
          sdate: '',
          edate: '',
          aspNewcustKey: '',
        },

      userInfo: {},

      dates: [
        (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
      ],

      commonArr : ["RE022", "OU003"],
      commonCode : [],
      eotTyCodeList : [],

      gridDataHeaders: [
        {
          text: 'No',
          align: 'center',
          value: 'index',
          width: '50px',
          sortable: true,
        },
        { text: '업무구분', value: 'IN_TY', align: 'center', sortable: true, },
        { text: '접수일자', value: 'IN_DATE',align: 'center', sortable: true, },
        { text: '접수시간', value: 'IN_TIME', align: 'center',sortable: true, },
        { text: '발신번호', value: 'SEND_MAN_NO', align: 'center',sortable: true, },
        { text: '고객명', value: 'CUST_NM', align: 'center',sortable: true, },
        { text: '고객키', value: 'INGNO', align: 'center',sortable: true, },
        { text: '고객주민번호', value: 'CUST_NO', align: 'center',sortable: true, },
        { text: '접수전화번호', value: 'CLBK_TEL_NO', align: 'center',sortable: true, },
        { text: '배분여부', value: 'DSTR_YN', align: 'center',sortable: true, },
        { text: '배분일자', value: 'DSTR_DATE', align: 'center',sortable: true, },
        { text: '배분상담원', value: 'DSTR_CSLT_NM', align: 'left',sortable: true, },
        { text: '시도횟수', value: 'TRY_CNT', align: 'center',sortable: true, },
        { text: '완료여부', value: 'EOT_TY_NM', align: 'center',sortable: true, },
      ],
      gridDataText: [],
      checkedList: [],

      ASP_NEWCUST_KEY: '',
      START_DATE: '',
      END_DATE: '',
      USER_ID: '',
      IN_TY: '',
      EOT_TY: '',

      UPDT_DIV: '',

      USER_NM: '',
      USER_NICK: '',

      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      totalVisible: 10,
      totcount: 0,
      selectedRow: null,
      
      userGroup: [], //사용자그룹
      selected_userGroup: [],

      RDOnly:false,
    }
  },
  mounted(){
    // 헤더 초기화 및 세팅
    this.newCommonCode;

    let chk = this.mixin_check_AspCustKey_number();
    if(chk == 'Y'){
      this.ASP_NEWCUST_KEY = this.$store.getters["userStore/GE_USER_ROLE"].company[0].CD;
      this.RDOnly = true;
      this.getUserGroup();
    } else {
      this.RDOnly = false;
    }
  },
  methods: {
    startDate(e){
      this.dates[0] = e;
    },
    endDate(e){
      this.dates[1] = e;
    },
    showDialog(type) {
      if(this.ASP_NEWCUST_KEY == '' || this.ASP_NEWCUST_KEY == null){
        this.common_alert('회사구분을 선택해주세요.', 'error');
        return;

      } else {
        if(type == 'M110303'){
          //팝업 파라미터 설정
          this.popupParams;
        }
        this[`dialog${type}`] = true;
      }
    },
    hideDialog(type) {
      this[`dialog${type}`] = false;
    },
    getListOrNot(){
      if(this.ASP_NEWCUST_KEY == '' || this.ASP_NEWCUST_KEY == null){
        this.common_alert('회사구분을 선택해주세요.', 'error');
      }else{
        this.getListData();
      }
    },
    async getListData(){
      let requestData = {
          headers: {},
          sendData:{},
      }
      
      let CHK_GROUP = this.selected_userGroup[0];
      for(let i=1;i<this.selected_userGroup.length;i++){
        CHK_GROUP = CHK_GROUP + "," + this.selected_userGroup[i];
      }

      //header 세팅
      requestData.headers["URL"] = "/api/phone/callback/manage/list";
      requestData.headers["METHOD"] = "list";
      requestData.headers["ASYNC"] = false;

      requestData.headers["PAGES_CNT"] = 1;
      requestData.headers["ROW_CNT"] = 100;

      //sendData 초기화
      requestData.sendData['ASP_NEWCUST_KEY'] = this.ASP_NEWCUST_KEY; //회사코드
      requestData.sendData['START_DATE'] = this.dates[0];             //접수시작일자
      requestData.sendData['END_DATE'] = this.dates[1];               //접수종료일자
      requestData.sendData['DSTR_CSLT_ID'] = this.USER_ID;       //배분상담사ID
      requestData.sendData['IN_TY'] = this.IN_TY;                     //업무구분
      requestData.sendData['EOT_TY'] = this.EOT_TY;                   //처리구분
      requestData.sendData["USER_GROUP"] = CHK_GROUP;                 //사용자그룹;

      //선택된 데이터 초기화
      this.checkedList = [];

      let response = await this.common_postCall(requestData);

      this.getListRes(response);
    },
    processOrNot(){
      //체크한 데이터 유무 확인 필요
      var chkData = this.checkedList;

      if(chkData == null || chkData == ''){
        //체크한 데이터가 없을 경우
        this.common_alert('완료처리 할 목록을 선택해주세요.', 'error');
        return;
      }else{
        //체크한 데이터가 있을 경우
        this.common_confirm('선택한 목록을 완료처리 하시겠습니까?', this.processBtn, null, null, null , 'chk');
      }
    },
    async processBtn(){
      //선택된 data
      var chkData = this.checkedList;
      let processList = [];

      for(var i=0; i<chkData.length; i++){
        //체크된 값만 추출
        if(chkData[i]["EOT_TY"] == null || chkData[i]["EOT_TY"] == ""){
          //결과값이 없을 경우
          let obj = {
            UPDT_DIV: "1",
            ASP_NEWCUST_KEY: this.ASP_NEWCUST_KEY,
            CLBK_NO: chkData[i]["CLBK_NO"],
            REG_MAN: this.$store.getters['userStore/GE_USER_ROLE'].userId,
            CHNG_MAN: this.$store.getters['userStore/GE_USER_ROLE'].userId,
          }
          processList.push(obj);
        } else {
          //결과값이 있을 경우
          let obj = {
            UPDT_DIV: "2",
            ASP_NEWCUST_KEY: this.ASP_NEWCUST_KEY,
            CLBK_NO: chkData[i]["CLBK_NO"],
            REG_MAN: this.$store.getters['userStore/GE_USER_ROLE'].userId,
            CHNG_MAN: this.$store.getters['userStore/GE_USER_ROLE'].userId,
          }
          processList.push(obj);
        }
      }

      let requestData = {
        headers: {},
        sendData: {
          DATA : processList
        }
      }

      requestData.headers["URL"] = "/api/phone/callback/manage/modify";
      requestData.headers["METHOD"] = "modify";
      requestData.headers["ASYNC"] = false;
      requestData.headers["PAGES_CNT"] = 1;
      requestData.headers["ROW_CNT"] = 1000;

      await this.common_postCall(requestData);

      //등록 후 재조회
      this.getListData();
    },
    getListRes(res){
      let header= res.HEADER;
      this.totcount = header.TOT_COUNT;

      let data = res.DATA;
      this.gridDataText = data;

      let idx = 1;
      for(var i in this.gridDataText){
        this.gridDataText[i]["index"] = idx++;
      }
    },
    resetBtn(){
      //사용자 조회 초기화
      this.userInfo.USER_ID = '';
      this.userInfo.USER_NM = '';
      this.userInfo.USER_NICK = '';
      this.USER_ID = '';
      this.USER_NM = '';
      this.USER_NICK = '';
    },
    downloadExcel(tableId, fileNm){
        //엑셀 다운로드
        this.table_id = tableId;
        this.file_name = fileNm;
        this.mixin_common_exportExcel();
    },
    dateFormat(date){
      //접수일자 날짜 포맷
      if(!date) return "";
      var newDate = "";

      //공백제거
      date = date.replace(/\s/gi, "");

      if(date.length == 8){
        newDate = date.replace(/(\d{4})(\d{2})(\d{2})/, '$1/$2/$3');
        return newDate;
      }else{
        return date;
      }
    },
    timeFormat(time){
       //접수시간 포맷
       var newTime ='';

       if(time.length == 6 ){
         newTime = time.substring(0,2) + ":" +  time.substring(2,4) + ":" +  time.substring(4,6);
         return newTime;
       }else{
         //추후 6자리 외의 데이터가 있을 경우 수정
         return time;
       }
    },
    phoneFormat(num){
      //핸드폰 번호 형식 만들기
      var phoneNum = num.replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`);
      return phoneNum;
    },
    juminFormat(num){
      //주민 번호 형식 만들기
      var juminNum = num.replace(/-/g, '').replace(/(\d{6})(\d{7})/g, '$1-$2');
      return juminNum;
    },
    setUser(){
      this.USER_ID = this.userInfo.USER_ID;
      this.USER_NM = this.userInfo.USER_NM;
      this.USER_NICK = this.userInfo.USER_NICK;
    },
    btnUser() {
      var user = {
        ASP_CUST_KEY: this.ASP_NEWCUST_KEY,
        USER_ID: this.USER_ID,
        USER_NM: this.USER_NM,
        USER_NICK: this.USER_NICK,
      };
      this.userInfo = user;
    },
    rowSelect(idx) {
      this.selectedRow = idx;
    },
    isActiveRow(item){
      const activeClass = item === this.selectedRow ? 'active' : '';
      return activeClass;
    },
    
    //소속정보를 가져와 사용자 그룹 셀렉트 박스 만들기
    async getUserGroup() {
      this.userGroup = await this.mixin_attr_get(this.ASP_NEWCUST_KEY, "B", "");
      let userGroupLeng = this.userGroup.length;
      let i = 0;
      this.selected_userGroup = [];
      for (i;i<userGroupLeng;i++){
        this.selected_userGroup[i] = this.userGroup[i].CD;
      }
    },

    removeDropGroup(index) {
      this.selected_userGroup.splice(index, 1);
    },

  },
  computed: {
    dateRangeText () {
      var newStartDate = this.dates[0];
      var newEndDate = this.dates[1];
      return newStartDate + ' ~ ' + newEndDate;
    },
    popupParams(){
      return this.params = {
        "sdate" : this.dates[0],
        "edate" : this.dates[1],
        "aspNewcustKey" : this.ASP_NEWCUST_KEY,
      }
    },
    async newCommonCode(){
      this.commonCode = await this.mixin_common_code_get_all(this.commonArr);

      //처리구분 콤보박스에서 처리내용완판(00007) 삭제한 값 리턴
      let eotTy = this.mixin_common_code_get(this.commonCode, 'OU003', '전체');
      for(var i=0; i<eotTy.length; i++){
        if(eotTy[i].value != '00007'){
          let codeObj = {
            CD : eotTy[i].value,
            CD_NM : eotTy[i].text,
          }
          this.eotTyCodeList.push(codeObj);
        }
      }
    },
  },
}
</script>

<style>

</style>
